import Topbar from "./topbar";
import Navbar from "./navbar";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Flash() {
  const [product, setProduct] = useState([]);
  const [cate, setCate] = useState([]);
  const [search, setSearch] = useState("");
  const [cprice, setCprice] = useState("");
  const [oprice, setOprice] = useState("");
  const [pid, setPid] = useState("");
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproduct", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
      })
      .catch((err) => console.log(err));
  }, [update]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCate(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  const flashDeal = () => {
    console.log(pid);
    const data = new FormData();
    data.append("oprice", oprice);
    data.append("pid", pid);

    fetch("https://server.itphonestore.com/itphone/flash", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));
  };

  const flashDealoff = (x) => {
    const data = new FormData();
    data.append("pid", x);

    fetch("https://server.itphonestore.com/itphone/flashoff", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Navbar />
      <div class="container-fluid panel-Background panel-BackgroundH py-4 px-5">
        <Topbar />
        <div class="productList px-5 mb-4">
          <div class="bg-light rounded py-3">
            <h4 class="text-secondary mb-3 px-5">Product List</h4>

            <form class="row px-5">
              <div class="col-auto">
                <label for="staticEmail2" class="visually-hidden">
                  Categories
                </label>
              </div>
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">
                  Search
                </label>
                <input
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  class="form-control"
                  id="inputPassword2"
                  placeholder="Product Name"
                />
              </div>
            </form>
            <br />

            <table class="table text-center fw-normal">
              <thead>
                <tr class="border-top border-bottom border-secondary">
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Product ID
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Product Name
                  </th>

                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Stock
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Sell Price
                  </th>

                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Flash Sell
                  </th>
                </tr>
              </thead>
              <tbody>
                {product
                  .filter((item) => {
                    if (
                      item.pname
                        ?.toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                    ) {
                      return item;
                    }

                    if (item.category?.includes(search)) {
                      return item;
                    }

                    if (item.date?.includes(search)) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <tr class="border-bottom border-secondary">
                      <td class="text-secondary">{item.id}</td>
                      <td class="text-secondary">
                        <img
                          width="150"
                          src={"http://itphonestore.com/image/" + item.pimage}
                        />
                        <br />
                        <Link to={"/sproduct/" + item.id}>{item.pname}</Link>
                      </td>

                      <td class="text-secondary">{item.quan}</td>
                      <td class="text-secondary">{item.price}</td>

                      <td class="text-secondary">
                        {item.offer == 1 ? (
                          <button
                            onClick={() => {
                              flashDealoff(item.id);
                            }}
                            className="btn btn-warning w-100"
                          >
                            Turn off
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setCprice(item.price);
                              setPid(item.id);
                            }}
                            className="btn btn-primary w-100"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Turn on
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Modal title
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <input
                type="text"
                class="form-control"
                placeholder={"Current Price : " + cprice}
                readOnly
              />
              <br />
              <input
                type="text"
                onChange={(e) => setOprice(e.target.value)}
                class="form-control"
                placeholder="Offer Price"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={flashDeal}
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Flash;
