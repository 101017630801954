import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Navbar from "./navbar";
import Topbar from "./topbar";

function Shop() {
  const [show, setShow] = useState(false);

  const [count, setCount] = useState("");
  useEffect(() => {
    if (localStorage.getItem("atoken") == null) {
      history.push("/login");
    } else {
      const data = new FormData();
      data.append("token", localStorage.getItem("atoken"));

      fetch("https://server.itphonestore.com/itphone/admininfo", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.message[0].section);
          if (res.message[0].section == "both") {
            setShow(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/newshoporder", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCount(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  let history = useHistory();

  const [product, setProduct] = useState([]);
  const [cate, setCate] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("atoken") == null) {
      history.push("/login");
    }

    fetch("https://server.itphonestore.com/itphone/stockalert", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCate(res.message);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Navbar />
      <div class="container-fluid py-4 px-5">
        <Topbar />
        <div class="quickUse px-5 mb-4">
          <div class="bg-white rounded py-3 px-5">
            <h4 class="text-center text-secondary mb-5">Quick Use</h4>
            <div class="row text-center px-5 mb-4">
              <div class="col-lg-3" style={{ position: "relative" }}>
                <Link
                  to={"/order"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Order
                </Link>
                <span class="neworderblink">{count}</span>
              </div>
              <div class="col-lg-3">
                <Link
                  to={"/addproduct"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Add Product
                </Link>
              </div>
              <div class="col-lg-3">
                <Link
                  to={"/product"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Product List
                </Link>
              </div>
              <div class="col-lg-3">
                <Link
                  to={"/flash"}
                  class="qu-btn btn btn-secondary text-secondary pt-3 fs-4"
                >
                  Flash Sell
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="itemStock px-5 mb-4">
          <div class="bg-white rounded py-3">
            <h5 class="text-center text-secondary mb-3">Item Stock Out Soon</h5>
            <table class="table text-center">
              <thead class="border-bottom border-top">
                <tr>
                  <th scope="col" class="text-secondary fw-normal">
                    Product ID
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Product Name
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Brand
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Category
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Expiry Date
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Available Stock
                  </th>
                  <th scope="col" class="text-secondary fw-normal">
                    Sell Price
                  </th>
                </tr>
              </thead>
              {product.map((item) => (
                <tr class="border-bottom border-secondary">
                  <td class="text-secondary">{item.id}</td>
                  <td class="text-secondary">
                    <Link to={"/sproduct/" + item.id}>{item.pname}</Link>
                  </td>
                  <td class="text-secondary">{item.brand}</td>
                  <td class="text-secondary">
                    {cate.map((y) => (
                      <>{y.id == item.category ? y.cname : null}</>
                    ))}
                  </td>
                  <td class="text-secondary">{item.edate}</td>
                  <td class="text-secondary">{item.quan}</td>
                  <td class="text-secondary">{item.price}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Shop;
