import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../image/icons/logo.png";
import neworder from "../image/neworder.gif";

function Navbar() {
  const [show, setShow] = useState(false);

  const [count, setCount] = useState("");
  useEffect(() => {
    if (localStorage.getItem("atoken") == null) {
      history.push("/login");
    } else {
      const data = new FormData();
      data.append("token", localStorage.getItem("atoken"));

      fetch("https://server.itphonestore.com/itphone/admininfo", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.message[0].section);
          if (res.message[0].section == "both") {
            setShow(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/newshoporder", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCount(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  let history = useHistory();
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <Link class="navbar-brand text-secondary ms-5 pe-4" to={"/shop"}>
          <img src={logo} class="navlogo" />
        </Link>
        {/* 
        {show ? (
          <Link className="nav-link" to={"/order"}>
            <p class="orders">
              New Order <span class="neworderblink">{count}</span>
            </p>
          </Link>
        ) : null} */}

        <div class="navbar-nav me-5 fs-5">
          {show ? (
            <Link
              class="nav-link border-end border-secondary fw-bold pe-5"
              to={"/"}
            >
              <small>Super Admin</small>
            </Link>
          ) : null}
          <a
            class="nav-link ps-5"
            href="#"
            onClick={() => {
              localStorage.removeItem("atoken");
              history.push("/login");
            }}
          >
            <small>Logout</small>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
