import Topbar from "./topbar";
import Navbar from "./navbar";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Product() {
  const [product, setProduct] = useState([]);
  const [cate, setCate] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchproductAdmin", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setProduct(res.message);
        console.log(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCate(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar />
      <div class="container-fluid panel-Background panel-BackgroundH py-4 px-5">
        <Topbar />
        <div class="productList px-5 mb-4">
          <div class="bg-light rounded py-3">
            <h4 class="text-secondary mb-3 px-5">Product List</h4>

            <form class="row px-5">
              <div class="col-auto">
                <label for="staticEmail2" class="visually-hidden">
                  Categories
                </label>
                <select
                  type="text"
                  class="form-control"
                  onChange={(e) => setSearch(e.target.value)}
                >
                  <option value="">All</option>
                  {cate.map((item) => (
                    <option value={item.id}>{item.cname}</option>
                  ))}
                </select>
              </div>
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">
                  Search
                </label>
                <input
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  class="form-control"
                  id="inputPassword2"
                  placeholder="Product Name"
                />
              </div>
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">
                  Date
                </label>
                <input
                  type="date"
                  onChange={(e) => setSearch(e.target.value)}
                  class="form-control"
                  id="inputPassword2"
                  placeholder="Select date"
                />
              </div>
            </form>
            <br />

            <table class="table text-center fw-normal">
              <thead>
                <tr class="border-top border-bottom border-secondary">
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Product ID
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Product Name
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Brand
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Category
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Expiry Date
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Stock
                  </th>
                  <th class="fw-normal text-secondary fw-bold" scope="col">
                    Sell Price
                  </th>
                </tr>
              </thead>
              <tbody>
                {product
                  .filter((item) => {
                    if (
                      item.pname
                        ?.toLocaleLowerCase()
                        .includes(search.toLocaleLowerCase())
                    ) {
                      return item;
                    }

                    if (item.category?.includes(search)) {
                      return item;
                    }

                    if (item.date?.includes(search)) {
                      return item;
                    }
                  })
                  .map((item) => (
                    <tr class="border-bottom border-secondary">
                      <td class="text-secondary">{item.id}</td>
                      <td class="text-secondary">
                        <Link to={"/sproduct/" + item.id}>{item.pname}</Link>
                      </td>
                      <td class="text-secondary">{item.brand}</td>
                      <td class="text-secondary">{item.category}</td>
                      <td class="text-secondary">{item.edate}</td>
                      <td class="text-secondary">{item.quan}</td>
                      <td class="text-secondary">{item.price}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
