import { useEffect } from "react";
import { useState } from "react";
import Topbar from "./topbar";

function Customization() {
  const [image, setImage] = useState("");
  const [covers, setCovers] = useState([]);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState(0);

  const [cate, setCate] = useState([]);

  const subFile = async (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCate(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/allcoverfetch", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => setCovers(res.message))
      .catch((err) => console.log(err));
  }, [covers]);

  function delcover(x) {
    const data = new FormData();
    data.append("id", x);

    fetch("https://server.itphonestore.com/itphone/delcover", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => console.log(res.message))
      .catch((err) => console.log(err));
  }

  function submitHandle() {
    const data = new FormData();
    data.append("title", title);
    data.append("category", category);
    data.append("image", image);

    fetch("https://server.itphonestore.com/itphone/coverpost", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));

    document.getElementById("cfrm").reset();
  }

  return (
    <div class="container-fluid panel-Background py-4 px-5">
      <Topbar />

      <div class="addProduct px-5 mb-4">
        <div class="bg-white rounded py-3 px-5">
          <div class="row pt-2 align-items-center">
            <div class="col-lg-12">
              <h4 class="text-secondary m-0">Website Cover Photo</h4>
            </div>
            <div class="col-lg-6 py-2 mt-3">
              <form className="d-flex" id="cfrm">
                <input
                  class="form-control w-100"
                  type="file"
                  onChange={subFile}
                />

                <div style={{ width: "30px" }}></div>

                <input
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  class="form-control"
                  placeholder="Campaign Title"
                />

                <div style={{ width: "30px" }}></div>

                <select
                  onChange={(e) => setCategory(e.target.value)}
                  className="form-control"
                >
                  <option value="0">Select Category</option>
                  <>
                    {cate.map((item) => (
                      <option value={item.id}>{item.cname}</option>
                    ))}
                  </>
                </select>
              </form>

              <button
                class="btn btn-blue my-3 w-25"
                onClick={() => submitHandle()}
              >
                Update
              </button>
            </div>

            <div style={{ display: "flex" }}>
              {covers.map((item) => (
                <div style={{ margin: 5 }}>
                  <img
                    src={"http://itphonestore.com/image/" + item.photo}
                    style={{ width: 150 }}
                  />
                  <div>
                    <i
                      onClick={() => {
                        delcover(item.id);
                      }}
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customization;
