import Navbar from "./navbar";
import Topbar from "./topbar";
import { useEffect, useState } from "react";

function Mailbox() {
  const [mailList, setMailList] = useState([]);
  const [update, setUpdate] = useState(0);
  const [activeId, setActiveId] = useState(0);

  function formatDate(date) {
    var today = new Date(date);

    var tdate = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    if (tdate < 10) {
      tdate = "0" + tdate;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return tdate + "/" + month + "/" + year;
  }

  useEffect(() => {
    fetch(`https://server.itphonestore.com/itphone/getmail`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setMailList(res.message);
        setActiveId(res.message[0].id);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [update]);
  return (
    <>
      <Navbar />
      <div class="container-fluid py-4 px-5">
        <Topbar />
        <div class="productList px-5 mb-4">
          <div class="bg-light rounded py-3">
            <h4 class="text-secondary mb-3 px-5">Mail List</h4>
            <div class="row mx-auto m-0 p-0 ">
              <div className="row m-0 p-0">
                <div className="col-5 p-0 maillist">
                  <ul class="list-group list-group-flush border-start ">
                    {mailList?.map((item) => (
                      <li
                        class={
                          item.id === activeId
                            ? "list-group-item  rounded-0 act"
                            : "list-group-item  rounded-0"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActiveId(item.id);
                        }}
                      >
                        <p className="m-0">{item.name}</p>
                        <p className="m-0">{item.email}</p>
                        <div class="position-absolute top-0 end-0 p-2">
                          {formatDate(item.date)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-7">
                  <div className="">
                    {mailList
                      ?.filter((item) => {
                        if (item.id === activeId) {
                          return item;
                        }
                      })
                      .map((item) => (
                        <>
                          <div class="card ">
                            <div class="card-header">
                              <p className="m-0 fw-bold">
                                Message from {item.name}
                              </p>

                              <div class="position-absolute top-0 end-0 p-2">
                                <i class="fa-regular fa-clock"></i>{" "}
                                {formatDate(item.date)}
                              </div>
                            </div>
                            <div class="card-body">
                              <p>
                                <span className="fw-bold"> From :</span>{" "}
                                {item.email}
                              </p>
                              <p>
                                <span className="fw-bold">Name :</span>{" "}
                                {item.name}
                              </p>
                              <div
                                className="overflow-auto"
                                style={{ height: "300px" }}
                              >
                                <p class="card-text">{item.message}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mailbox;
