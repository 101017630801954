import Barcode from "react-barcode";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Topbar from "./topbar";
import Navbar from "./navbar";
import catgif from "../image/productcat.gif";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function Addproduct() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [cname, setCname] = useState("");
  const [image, setImage] = useState("");

  const [pname, setPname] = useState("");
  const [quan, setQuan] = useState("");
  const [price, setPrice] = useState("");

  const [pimage, setPimage] = useState("");
  const [pimage2, setPimage2] = useState("");
  const [pimage3, setPimage3] = useState("");

  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [unit, setUnit] = useState("");
  const [pprice, setPprice] = useState("");
  const [salert, setSalert] = useState("");
  const [des, setDes] = useState("");
  const [cid, setCid] = useState("");
  const [edate, setEdate] = useState("");
  const [lid, setLid] = useState("");
  const [color, setColor] = useState("#000000");
  const [size, setSize] = useState("");
  const [stock, setStock] = useState("");
  const [load, setLoad] = useState(false);

  const [cate, setCate] = useState([]);
  const [variant, setVariant] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [desImage, setDesImage] = useState([]);
  const [update, setUpdate] = useState(0);
  const [release, setRelease] = useState(0);
  const [main, setMain] = useState("");

  const desFile = async (e) => {
    const file = e.target.files[0];

    const data = new FormData();
    data.append("prodId", lid);
    data.append("desImage", e.target.files[0]);

    fetch("https://server.itphonestore.com/itphone/desImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdate(update + 1);
        toast.success("Image Updated!", {
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          toastId: "success4",
          autoClose: 2000,
        });
      })
      .catch((err) => console.log(err));
  };

  const subFile = async (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const subFile2 = async (e) => {
    const file = e.target.files[0];
    setPimage(file);
  };

  const subFile3 = async (e) => {
    const file = e.target.files[0];
    setPimage2(file);
  };

  const subFile4 = async (e) => {
    const file = e.target.files[0];
    setPimage3(file);
  };

  const postVariant = () => {
    if (stock != "") {
      const data = new FormData();
      data.append("color", color);
      data.append("size", size);
      data.append("stock", stock);
      data.append("pid", lid);

      fetch("https://server.itphonestore.com/itphone/postvariant", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((res) => {
          console.log(res.message);
          setUpdate(update + 1);
          setColor("");
          setStock("");
          setSize("");
        })
        .catch((err) => console.log(err));
    } else {
      alert("Stock is empty");
    }
  };

  function totalStock() {
    var total = 0;
    variant.map((item) => {
      total = Number(total) + Number(item.stock);
    });

    return total;
  }

  function delVariation(id) {
    const data = new FormData();
    data.append("id", id);
    fetch("https://server.itphonestore.com/itphone/delVariation", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCate(res.message);
        setLid(res.last);
        console.log(res.last);
      })
      .catch((err) => console.log(err));
  }, [update]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchBrand", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setBrandList(res.message);
      })
      .catch((err) => console.log(err));
  }, [update]);

  useEffect(() => {
    const data = new FormData();
    data.append("pid", lid);
    fetch("https://server.itphonestore.com/itphone/fetchvariant", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setVariant(res.message);
      })
      .catch((err) => console.log(err));
  }, [update]);

  useEffect(() => {
    const data = new FormData();
    data.append("prodId", lid);
    fetch("https://server.itphonestore.com/itphone/delByprod", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));
  }, [lid]);

  useEffect(() => {
    const data = new FormData();
    data.append("prodId", lid);
    fetch("https://server.itphonestore.com/itphone/getDesImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setDesImage(res.message);
      })
      .catch((err) => console.log(err));
  }, [update]);

  function submitHandleCategory() {
    console.log(main);
    const data = new FormData();
    data.append("cname", cname);

    fetch("https://server.itphonestore.com/itphone/catepost", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));

    document.getElementById("cfrm").reset();
  }

  function submitHandleBrand() {
    const data = new FormData();
    data.append("cname", cname);
    data.append("brand", brand);
    data.append("image", image);

    fetch("https://server.itphonestore.com/itphone/brandpost", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUpdate(update + 1);
        toast.success("Brand added successfully, Check Category !", {
          icon: "🛒",
          position: "top-center",
        });
      })
      .catch((err) => console.log(err));

    document.getElementById("brandFrm").reset();
  }

  function submitHandle2() {
    const data = new FormData();
    data.append("pname", pname);
    data.append("quan", totalStock());
    data.append("price", price);
    data.append("pprice", pprice);
    data.append("unit", unit);
    data.append("cid", cid);
    data.append("pimage", pimage);

    data.append("pimage2", pimage2);
    data.append("pimage3", pimage3);

    data.append("category", category);
    data.append("brand", brand);
    data.append("salert", salert);
    data.append("des", des);
    data.append("edate", edate);
    data.append("release", release);

    fetch("https://server.itphonestore.com/itphone/productpost", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        toast.success("Product added successfully !", {
          icon: "🛒",
        });

        setUpdate(update + 1);
      })
      .catch((err) => console.log(err));

    document.getElementById("pfrm").reset();
  }

  return (
    <>
      <Navbar />
      <div class="container-fluid panel-Background py-4 px-5">
        <ToastContainer />
        <div
          class="modal fade"
          id="cateModal"
          tabindex="-1"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog prcategory">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Product Category
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-lg-6">
                    <p class="fw-bold">New Category</p>
                    <form id="cfrm">
                      <input
                        class="form-control"
                        placeholder="Category"
                        onChange={(e) => setCname(e.target.value)}
                      />
                      <br />
                    </form>

                    <button
                      class="btn addProd-btn w-100"
                      onClick={submitHandleCategory}
                    >
                      Add Category
                    </button>

                    <br />
                    <br />
                    <br />

                    <p class="fw-bold">New Brand</p>
                    <form id="brandFrm">
                      <select
                        onChange={(e) => setCname(e.target.value)}
                        class="form-control"
                      >
                        <option>Choose Category</option>
                        {cate.map((item) => (
                          <option>{item.cname}</option>
                        ))}
                      </select>

                      <br />
                      <input
                        class="form-control"
                        placeholder="Brand Name"
                        onChange={(e) => setBrand(e.target.value)}
                      />

                      <br />
                      <input
                        type="file"
                        class="form-control"
                        onChange={subFile}
                      />
                      <br />
                    </form>

                    <button
                      class="btn addProd-btn w-100"
                      onClick={submitHandleBrand}
                    >
                      Add Brand
                    </button>

                    <div class="text-center mt-2">
                      <img src={catgif} class="catgif" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <p class="fw-bold">Category List</p>
                    <ul class="catlistm list-group">
                      {cate.map((item) => (
                        <li
                          class="py-2 border-bottom"
                          style={{ textDecoration: "none" }}
                        >
                          <div className="d-flex justify-content-between">
                            <div class="b-text m-0">{item.cname}</div>
                            <div>
                              <a
                                data-bs-toggle="collapse"
                                href={
                                  "#" +
                                  item.cname
                                    .replace(/\s+/g, "-")
                                    .replace("&", "-")
                                }
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                              >
                                <i
                                  className="fa fa-angle-right"
                                  style={{ float: "right" }}
                                ></i>
                              </a>
                            </div>
                          </div>

                          <div
                            class="collapse"
                            id={item.cname
                              .replace(/\s+/g, "-")
                              .replace("&", "-")}
                          >
                            <div>
                              {brandList.map((brand) => (
                                <ul>
                                  {brand.brandCate == item.cname ? (
                                    <li>
                                      <img
                                        src={
                                          "http://itphonestore.com/image/" +
                                          brand.brandImage
                                        }
                                        style={{ height: 30, marginRight: 6 }}
                                      />
                                      {brand.brandName}
                                    </li>
                                  ) : null}
                                </ul>
                              ))}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog variationmodal">
            <div class="modal-content variationmodal-b">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Variations
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="container">
                  <div class="row g-3 align-items-center">
                    <div class="col-sm-4">
                      <input
                        type="color"
                        value={color}
                        class="form-control"
                        onChange={(e) => setColor(e.target.value)}
                      />
                    </div>
                    <div class="col-sm-4">
                      {/* <input
                        type="text"
                        value={size}
                        class="form-control"
                        placeholder="Size"
                        onChange={(e) => setSize(e.target.value)}
                      /> */}
                    </div>
                    <div class="col-sm-4">
                      <input
                        type="text"
                        value={stock}
                        class="form-control"
                        placeholder="Stock"
                        onChange={(e) => setStock(e.target.value)}
                      />
                    </div>
                  </div>

                  {variant.map((item) => (
                    <div className="row bg-light my-1 align-items-center">
                      <div className="col-lg-4">
                        <div
                          style={{
                            "background-color": item.color,
                            width: 20,
                            height: 20,
                          }}
                        ></div>
                      </div>
                      <div className="col-lg-4"></div>
                      <div className="col-lg-2">{item.stock}</div>
                      <div className="col-lg-2">
                        <i
                          onClick={() => {
                            delVariation(item.id);
                          }}
                          className="fa fa-trash"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  onClick={postVariant}
                  class="btn btn-secondary"
                >
                  Add More
                </button>
                <button
                  type="button"
                  onClick={postVariant}
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>

        <Topbar />
        <div class="addProduct px-5 mb-4">
          <div class="bg-white rounded py-3 px-5">
            <div class="row pt-2 align-items-center">
              <div class="col-lg-8">
                <h4 class="text-secondary m-0">Add Product</h4>
              </div>
              <div class="col-lg-4 row">
                <div class="col-lg-5">
                  <Link
                    to={"/product"}
                    class="btn  addProd-btn w-100 rounded-0"
                  >
                    Product List
                  </Link>
                </div>
                <div class="col-lg-7 text-end">
                  <button
                    class="btn addProd-btn w-100 rounded-0"
                    data-bs-toggle="modal"
                    data-bs-target="#cateModal"
                  >
                    Product Category
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-lg-8">
                <form id="pfrm">
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <label for="productID" class="form-label text-secondary">
                        Product ID
                      </label>
                      <input
                        type="text"
                        class="form-control bg-light rounded-0"
                        id="productID"
                        placeholder=""
                        value={lid}
                        onChange={(e) => setLid(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <label for="productID" class="form-label addProduct-text">
                        Product Name
                      </label>
                      <input
                        type="text"
                        class="form-control rounded-0"
                        id="productID"
                        placeholder=""
                        onChange={(e) => setPname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <label for="productID" class="form-label addProduct-text">
                        Product Category
                      </label>
                      <select
                        class="form-select rounded-0 text-secondary"
                        onChange={(e) => setCid(e.target.value)}
                      >
                        <option value="">Choose Category</option>
                        {cate.map((category) => (
                          <option>{category.cname}</option>
                        ))}
                      </select>
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label for="productID" class="form-label addProduct-text">
                        Product Brand
                      </label>
                      <select
                        onChange={(e) => setBrand(e.target.value)}
                        class="form-select rounded-0 text-secondary"
                      >
                        <option>Choose Brand</option>
                        {brandList.map((item) => (
                          <>
                            {item.brandCate == cid ? (
                              <option>{item.brandName}</option>
                            ) : null}
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-3 mb-3">
                      <label for="productID" class="form-label text-secondary">
                        Variations
                      </label>
                      <p
                        className="btn btn-primary w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        Add Variations
                      </p>
                    </div>
                    <div class="col-lg-3 mb-3">
                      <label for="productID" class="form-label text-secondary">
                        Purchase Price (AED)
                      </label>
                      <input
                        type="text"
                        class="form-control rounded-0"
                        id="productID"
                        placeholder=""
                        onChange={(e) => setPprice(e.target.value)}
                      />
                    </div>
                    <div class="col-lg-3 mb-3">
                      <label for="productID" class="form-label addProduct-text">
                        Sell Price (AED)
                      </label>
                      <input
                        type="text"
                        class="form-control rounded-0"
                        id="productID"
                        placeholder=""
                        onChange={(e) => setPrice(e.target.value)}
                      />
                    </div>

                    <div class="col-lg-2 mb-3">
                      <label for="productID" class="form-label addProduct-text">
                        New Release
                      </label>
                      <input
                        type="checkbox"
                        value={release == 0 ? 1 : 0}
                        style={{ marginLeft: 10 }}
                        onChange={(e) => setRelease(e.target.value)}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <label for="productID" class="form-label text-secondary">
                        Minimum Stock Alert
                      </label>
                      <input
                        type="text"
                        class="form-control rounded-0"
                        id="productID"
                        placeholder=""
                        onChange={(e) => setSalert(e.target.value)}
                      />
                    </div>
                    <div class="col-lg-6 mb-3">
                      <label for="formFile" class="form-label addProduct-text">
                        Product Image
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        onChange={subFile2}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div class="col-lg-6 mb-3">
                      <label for="formFile" class="form-label addProduct-text">
                        Product Image 2
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        onChange={subFile3}
                      />
                    </div>

                    <div class="col-lg-6 mb-3">
                      <label for="formFile" class="form-label addProduct-text">
                        Product Image 3
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        onChange={subFile4}
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <label
                        for="productDesc"
                        class="form-label text-secondary"
                      >
                        Product Description
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        data=""
                        onReady={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDes(data);
                        }}
                        onBlur={(event, editor) => {
                          console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          console.log("Focus.", editor);
                        }}
                      />

                      {/* <textarea
                        class="form-control"
                        id="productDesc"
                        rows="3"
                        onChange={(e) => setDes(e.target.value)}
                      ></textarea> */}
                    </div>
                  </div>

                  <div className="row">
                    {desImage.map((item) => (
                      <div className="col-sm-3">
                        <img
                          src={"http://itphonestore.com/image/" + item.desImage}
                          width={150}
                        />
                      </div>
                    ))}
                  </div>
                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <label
                        for="productDesc"
                        class="form-label text-secondary"
                      >
                        Description Image
                      </label>

                      <input
                        type="file"
                        class="form-control"
                        onChange={desFile}
                      />
                    </div>
                  </div>
                </form>
                <div class="row">
                  <div class="col-lg-4 mb-3">
                    <button
                      onClick={submitHandle2}
                      class="btn addprbtn rounded-0 mt-0"
                    >
                      Add Product
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-lg-12 mb-3">
                    <div class="barcodePrev mt-3 bg-light p-2">
                      <p class="text-center barcodePrev-text">
                        Barcode Preview
                      </p>
                      <div class="text-center">
                        <Barcode value={lid} width={3} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Addproduct;
