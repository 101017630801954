import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

function Invoice(props) {
  const id = props.match.params.id;

  const [order, setOrder] = useState([]);
  const [orderitem, setOrderitem] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const data = new FormData();
    data.append("id", id);
    fetch("https://server.itphonestore.com/itphone/fetchsingleorder", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setOrder(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const data = new FormData();
    data.append("id", id);
    fetch("https://server.itphonestore.com/itphone/fetchsingleprod", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setOrderitem(res.message);
        console.log(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const data = new FormData();
    data.append("id", id);
    fetch("https://server.itphonestore.com/itphone/fetchuser", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((res) => {
        setUsers(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function print() {
    window.print();
  }
  return (
    <div id="invoice">
      <div class="invoice overflow-auto">
        <div>
          <header>
            <div class="row">
              <div class="col">
                <img
                  width="200"
                  src={require("../image/icons/logo.png")}
                  data-holder-rendered="true"
                />
              </div>
              <div class="col company-details">
                <div>M.B. Complex, 90 High Level Road</div>
                <div>01533748448</div>
                <div>sandwipmall@gmail.com</div>
              </div>
            </div>
          </header>

          {order.map((item) => (
            <main>
              <div class="row contacts">
                {users
                  .filter((y) => {
                    if (y.id == item.cid) {
                      return y;
                    }
                  })
                  .map((y) => (
                    <>
                      <div class="col invoice-to">
                        <h5 class="font-weight-bold border-bottom dorder-dark text-dark">
                          Customer Info
                        </h5>
                        <p class="fw-bold mb-0">Customer Name: {y.fname}</p>
                        <p class="fw-bold mb-0">Address: {y.address}</p>
                        <p class="fw-bold mb-0">Contact: {y.email}</p>
                      </div>
                      <div class="col invoice-to">
                        <h5 class="font-weight-bold border-bottom dorder-dark text-dark">
                          Delivery Info
                        </h5>
                        <p class="fw-bold mb-0">Location: {}</p>
                        <p class="fw-bold mb-0">Mobile: {}</p>
                      </div>
                    </>
                  ))}

                <div class="col invoice-details">
                  <h3 class="invoice-id">INVOICE #{item.id}</h3>
                </div>
              </div>
              <table
                border="0"
                cellspacing="0"
                cellpadding="0"
                width="100%"
                class="table-bordered invoicetable border-dark"
              >
                <thead>
                  <tr>
                    <th width="5%" class="text-center">
                      SL
                    </th>
                    <th width="50%" class="text-center">
                      Product
                    </th>
                    <th width="5%" class="text-center">
                      Color
                    </th>
                    <th width="10%" class="text-center">
                      Quantity
                    </th>
                    <th width="15%" class="text-end px-2">
                      Price
                    </th>
                    <th width="15%" class="text-end px-2">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orderitem.map((x) => (
                    <tr>
                      <td class="text-center font-color">{x.id}</td>
                      <td class="font-color px-2">
                        <h6>{x.name}</h6>
                      </td>
                      <td class="d-flex justify-content-center py-2 text-center">
                        <div
                          style={{
                            "background-color": x.color,
                            width: 20,
                            height: 20,
                          }}
                        ></div>
                      </td>

                      <td class="qty no text-center font-color">{x.quan}</td>
                      <td class="text-end font-color px-2">{x.price} ৳</td>
                      <td class="text-end font-color px-2">
                        {x.quan * x.price} ৳
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2"></td>
                    <td colspan="3" class="text-end font-color p-2">
                      Subtotal
                    </td>
                    <td class="text-end font-color p-2">{item.total} ৳</td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td colspan="3" class="text-end font-color p-2">
                      Delivery Fee
                    </td>
                    <td class="text-end font-color p-2">0 ৳</td>
                  </tr>
                  <tr>
                    <td class="font-color" colspan="2"></td>
                    <td
                      class="text-end font-color p-2 fw-bold fs-5"
                      colspan="3"
                    >
                      Grand Total
                    </td>
                    <td class="text-end font-color p-2 fw-bold fs-5">
                      {item.total} ৳
                    </td>
                  </tr>
                </tfoot>
              </table>
            </main>
          ))}
        </div>
        <div class="text-right">
          <button onClick={() => print()} class="btn btn-info">
            <i class="fa fa-print"></i> Print
          </button>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Invoice);
