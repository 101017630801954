import Topbar from "./topbar";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Invoicelist() {
  const [pos, setPos] = useState([]);
  const [online, setOnline] = useState([]);
  const [cate, setCate] = useState([]);
  const [type, setType] = useState("online");
  const [search, setSearch] = useState("");
  const [user, setUser] = useState([]);
  const [delivery, setDelivery] = useState([]);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchinvoicedata", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setPos(res.message);
        setOnline(res.message2);
        setDelivery(res.message3);
        console.log(res.message3);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchcate", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setCate(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch("https://server.itphonestore.com/itphone/fetchuser", {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setUser(res.message);
      })
      .catch((err) => console.log(err));
  }, []);

  function formatDate(date) {
    var date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return (
      date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      strTime
    );
  }

  return (
    <>
      <div class="container-fluid panel-Background panel-BackgroundH py-4 px-5">
        <Topbar />
        <div class="productList px-5 mb-4">
          <div class="bg-light rounded py-3">
            <h4 class="text-secondary mb-3 px-5">Invoice List</h4>

            <form class="row px-5">
              <div class="col-auto">
                <label for="staticEmail2" class="visually-hidden">
                  Categories
                </label>
                <select
                  type="text"
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="online">Online</option>
                </select>
              </div>
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">
                  Search
                </label>
                <input
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  class="form-control"
                  id="inputPassword2"
                  placeholder="Invoice number"
                />
              </div>
              <div class="col-auto">
                <label for="inputPassword2" class="visually-hidden">
                  Date
                </label>
                <input
                  type="date"
                  onChange={(e) => setSearch(e.target.value)}
                  class="form-control"
                  id="inputPassword2"
                  placeholder="Select date"
                />
              </div>
            </form>
            <br />

            {type == "pos" ? (
              <table class="table text-center fw-normal">
                <thead>
                  <tr class="border-top border-bottom border-secondary">
                    <th class="fw-normal text-secondary" scope="col">
                      Date
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Invoice Number
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Payment type
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Discount
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Vat
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pos
                    .filter((item) => {
                      if (
                        item.id
                          ?.toString()
                          .toLocaleLowerCase()
                          .includes(search?.toString().toLocaleLowerCase())
                      ) {
                        return item;
                      }

                      if (
                        item.date
                          ?.toString()
                          .toLocaleLowerCase()
                          .includes(search?.toString().toLocaleLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item) => (
                      <tr class="border-bottom border-secondary">
                        <td class="text-secondary">{formatDate(item.date)}</td>
                        <td class="text-secondary">FLS#{item.id}</td>
                        <td
                          class="text-secondary"
                          style={{ textTransform: "uppercase" }}
                        >
                          {item.type}
                        </td>
                        <td class="text-secondary">{item.dis}</td>
                        <td class="text-secondary">{item.vat}</td>
                        <td class="text-secondary">{item.total}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : type == "online" ? (
              <table class="table text-center fw-normal">
                <thead>
                  <tr class="border-top border-bottom border-secondary">
                    <th class="fw-normal text-secondary" scope="col">
                      Invoice Number
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Customer name
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Customer phone
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Delivery address
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Date
                    </th>
                    <th class="fw-normal text-secondary" scope="col">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {online
                    .filter((item) => {
                      if (
                        item.id
                          ?.toString()
                          .toLocaleLowerCase()
                          .includes(search?.toString().toLocaleLowerCase())
                      ) {
                        return item;
                      }
                    })
                    .map((item) => (
                      <tr class="border-bottom border-secondary">
                        <td class="text-secondary"># {item.id}</td>

                        {user
                          .filter((y) => {
                            if (y.id == item.cid) {
                              return y;
                            }
                          })
                          .map((y) => (
                            <>
                              <td class="text-secondary">{y.fname}</td>
                              <td class="text-secondary">{y.phone}</td>
                            </>
                          ))}

                        <td class="text-secondary">
                          {delivery
                            .filter((x) => {
                              if (x.id == item.did) {
                                return x;
                              }
                            })
                            .map((x) => (
                              <>
                                {x.hname +
                                  " " +
                                  x.sname +
                                  " " +
                                  x.aname +
                                  " " +
                                  x.street}
                              </>
                            ))}
                        </td>
                        <td class="text-secondary">{formatDate(item.date)}</td>
                        <td class="text-secondary">
                          <Link to={"/invoice/" + item.id}>
                            <i class="fa fa-download" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default Invoicelist;
