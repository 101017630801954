import Navbar from "./navbar";
import Topbar from "./topbar";
import { useEffect, useState } from "react";
function Quotation() {
  const [quatation, setQuatation] = useState([]);
  const [update, setUpdate] = useState(0);
  const [activeId, setActiveId] = useState(0);

  let d = new Date();
  let date = d.getDate();

  console.log(date);

  function formatDate(date) {
    var today = new Date(date);

    var tdate = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    if (tdate < 10) {
      tdate = "0" + tdate;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return tdate + "/" + month + "/" + year;
  }

  useEffect(() => {
    fetch(`https://server.itphonestore.com/itphone/getquatation`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setQuatation(res.message);
        setActiveId(res.message[0].id);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [update]);
  return (
    <>
      <Navbar />
      <div class="container-fluid py-4 px-5">
        <Topbar />
        <div class="productList px-5 mb-4">
          <div class="bg-light rounded py-3">
            <h5 class="text-secondary mb-3 px-5">Quotation List</h5>
            <div className="border border-mute border-1 "></div>
            <div class="row mx-auto m-0 p-0">
              <div className="row m-0 p-0">
                <div className="col-5 p-0 maillist border-end border-mute border-1 ">
                  <ul class="list-group list-group-flush border-start ">
                    {quatation?.map((item) => (
                      <li
                        class={
                          item.id === activeId
                            ? "list-group-item  rounded-0 act"
                            : "list-group-item  rounded-0"
                        }
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActiveId(item.id);
                        }}
                      >
                        <b className="m-0">{item.name}</b>
                        <p className="m-0">{item.email}</p>
                        <div class="position-absolute top-0 end-0 p-2">
                          {formatDate(item.date)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-7">
                  <div className="position-absolute">
                    {quatation
                      ?.filter((item) => {
                        if (item.id === activeId) {
                          return item;
                        }
                      })
                      .map((item) => (
                        <>
                          <p className="mt-2">
                            <span className="fw-bold">Date :</span>{" "}
                            {formatDate(item.date)}
                          </p>
                          <p>
                            <span className="fw-bold">Email :</span>{" "}
                            {item.email}
                          </p>
                          <p>
                            <span className="fw-bold">Name :</span> {item.name}
                          </p>
                          <p>
                            <span className="fw-bold">Address :</span>{" "}
                            {item.address}
                          </p>
                          <p>
                            <span className="fw-bold">Phone :</span>{" "}
                            {item.phone}
                          </p>
                          <p>
                            <span className="fw-bold">Product Type :</span>{" "}
                            {item.ptype}
                          </p>
                          <p>
                            <span className="fw-bold">Brand :</span>{" "}
                            {item.brand}
                          </p>
                          <p>
                            <span className="fw-bold">Device :</span>{" "}
                            {item.device}
                          </p>
                          <p>
                            <span className="fw-bold">QTY :</span> {item.qty}
                          </p>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Quotation;
